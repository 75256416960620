@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap");
.navigation {
  padding: 2em 1em 0 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .navigation a {
    margin-left: 2em;
    text-decoration: none;
    transition: .3s;
    border-bottom: solid 2px transparent;
    padding-bottom: .2em;
    color: #363636; }
    .navigation a:hover, .navigation a.active {
      color: #308749; }
  @media all and (max-width: 1200px) {
    .navigation {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      z-index: 9;
      padding: 0; }
      .navigation .Nav {
        width: 100%;
        text-align: center;
        padding: 2em 0;
        box-shadow: 0 10px 45px 10px rgba(0, 0, 0, 0.2);
        border-radius: 3em 3em 0 0;
        background: #FFF; }
        .navigation .Nav a {
          margin: 0 2em;
          font-size: 1.2em; } }
  @media all and (max-width: 768px) {
    .navigation .Nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 1em; }
      .navigation .Nav a {
        margin: 0;
        font-size: .8em;
        flex: .33; } }

.headerBackground {
  background-image: url(/src/Assets/Images/header.svg);
  height: 100vh;
  width: 60vh;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .headerBackground .logo {
    width: 20em;
    transform: translateX(-4vw); }
    @media all and (max-width: 1024px) and (min-height: 1024px) {
      .headerBackground .logo {
        width: 55% !important;
        transform: translateX(0); } }
    @media all and (max-width: 767px) {
      .headerBackground .logo {
        width: 55% !important;
        transform: translateX(0) translateY(-3em); } }
  .headerBackground.headerDetails {
    align-items: flex-start;
    padding-left: 3em;
    overflow: hidden; }
    .headerBackground.headerDetails .back {
      width: 3em;
      position: absolute;
      top: 2em;
      left: 2em; }
    .headerBackground.headerDetails h1 {
      color: #FFF;
      font-size: 3em; }
    .headerBackground.headerDetails .names {
      margin-left: -3em;
      margin-bottom: 3em;
      text-align: center;
      color: #FFF; }
      .headerBackground.headerDetails .names h1, .headerBackground.headerDetails .names h2 {
        font-size: 3em;
        margin: 0; }
    .headerBackground.headerDetails .images {
      display: flex;
      flex-direction: row;
      width: 50%; }
      .headerBackground.headerDetails .images img {
        width: 90%; }
    @media all and (max-width: 991px) and (max-height: 990px) {
      .headerBackground.headerDetails h1 {
        font-size: 2em;
        margin-bottom: 0; }
      .headerBackground.headerDetails img {
        width: 10em; }
      .headerBackground.headerDetails .names {
        margin-bottom: 0; }
        .headerBackground.headerDetails .names h1, .headerBackground.headerDetails .names h2 {
          font-size: 2em; }
      .headerBackground.headerDetails .images {
        width: 100%; }
        .headerBackground.headerDetails .images img {
          width: 6em; } }

.plantName {
  width: 15em; }

.plantContent {
  text-align: center;
  padding: 5em 0 1em 0; }
  .plantContent h1 {
    font-size: 1.7em;
    margin-bottom: 2em; }

.plantList {
  display: flex;
  list-style: none;
  flex-direction: row;
  width: 95%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .plantList .plantItem {
    width: 17%;
    box-sizing: border-box;
    box-shadow: 0 5px 20px #0000001a;
    padding: .3em;
    margin: 2em 1% .2em 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .4em;
    cursor: pointer;
    text-decoration: none;
    transition: .3s; }
    .plantList .plantItem span {
      text-align: center;
      color: #308749;
      font-weight: bold;
      font-size: .9em;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3em;
      line-height: 1.1em;
      transition: .3s; }
    .plantList .plantItem img {
      width: 4em;
      height: 4em;
      margin-top: -2em;
      object-fit: contain;
      border: none;
      transition: .3s; }
    .plantList .plantItem:hover {
      box-shadow: 0 5px 20px rgba(20, 109, 0, 0.2); }
      .plantList .plantItem:hover span, .plantList .plantItem:hover img {
        transform: translateY(-0.3em); }
    .plantList .plantItem.goodItem {
      background: #00cf5a;
      background: linear-gradient(180deg, #00cf5a 0%, #007436 100%);
      background-position: top; }
      .plantList .plantItem.goodItem span {
        color: #FFF; }
    .plantList .plantItem.wrongItem {
      background: #cf0001;
      background: linear-gradient(180deg, #cf0001 0%, #740000 100%);
      background-position: top; }
      .plantList .plantItem.wrongItem span {
        color: #FFF; }
  @media all and (max-width: 1200px) {
    .plantList {
      width: 100%;
      padding: 0 2em 0em 2em;
      box-sizing: border-box; }
      .plantList .plantItem {
        width: 23%; }
      .plantList:last-child {
        padding-bottom: 10em; } }
  @media all and (max-width: 768px) {
    .plantList {
      padding: 0 1em 1em 1em; }
      .plantList .plantItem {
        width: 29%;
        margin: 1em 2%; } }

.plantContent {
  display: flex;
  flex-direction: column; }
  .plantContent .infoContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-bottom: 3em; }
    .plantContent .infoContent .title {
      flex: .3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.2em; }
      .plantContent .infoContent .title img {
        height: 5em;
        object-fit: contain; }
    .plantContent .infoContent .infosPlants {
      flex: .7;
      padding: 1em;
      background-color: #FFF;
      box-shadow: 0 5px 20px #0000001a;
      border-radius: .3em; }
      .plantContent .infoContent .infosPlants .plantTitle {
        color: #308749;
        font-weight: bold;
        letter-spacing: .2px; }
      .plantContent .infoContent .infosPlants .sepLine {
        margin: 1em auto;
        width: 10em;
        height: 2px;
        background: #eaeaea; }
      .plantContent .infoContent .infosPlants p {
        color: #686868; }
  @media all and (max-width: 1200px) {
    .plantContent {
      padding: 0; } }

.assoDescription {
  text-align: center;
  background: #f1f1f1;
  padding: 1em;
  box-sizing: border-box;
  width: 90%;
  margin-bottom: 3em; }
  @media all and (max-width: 768px) {
    .assoDescription {
      padding: 2em;
      margin: 1em 1em 3em 1em; } }

@media all and (max-width: 991px) and (max-height: 990px) {
  .plantAsso {
    padding-top: 3em; } }

@media all and (max-width: 768px) {
  .plantAsso {
    flex-direction: row;
    width: 100%;
    padding: 0 1em;
    box-sizing: border-box; }
    .plantAsso h1 {
      margin: 0 0 2em 0;
      font-size: 1.2em; }
    .plantAsso .infoContent {
      flex-direction: column;
      width: 45%;
      margin: 0 2.5%; }
      .plantAsso .infoContent .title img {
        width: 40%; }
      .plantAsso .infoContent .infosPlants {
        width: 100%;
        flex: auto;
        box-sizing: border-box; }
        .plantAsso .infoContent .infosPlants .sepLine {
          width: 100%; }
        .plantAsso .infoContent .infosPlants p {
          font-size: .9em; }
      .plantAsso .infoContent:first-child .title img {
        width: 80%; } }

.emptyWrong {
  height: 3em; }

.lexique {
  list-style: none;
  text-align: left;
  padding-right: 3em; }
  .lexique li {
    margin: 1em 0 .4em 0; }
    .lexique li strong {
      color: #308749; }
    .lexique li p {
      color: #4b4b4b;
      margin-top: 0; }

.infos {
  padding: 1em 3em; }
  .infos strong {
    font-size: 1.5em; }
  .infos a {
    color: #308749;
    text-decoration: none;
    transition: .3s; }
    .infos a:hover {
      color: #000; }
  .infos:last-child {
    padding-bottom: 10em; }

.partnersInfos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .partnersInfos .partner {
    width: 7em;
    margin: 1em; }

.loader {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  z-index: 9999;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .3s;
  transition-delay: .5s;
  opacity: 0;
  visibility: hidden; }
  .loader img, .loader span {
    transition: .5s;
    opacity: 0;
    position: relative;
    top: -3em; }
  .loader span {
    transition-delay: .1s; }
  .loader.loading {
    opacity: 1;
    visibility: visible; }
    .loader.loading img, .loader.loading span {
      opacity: 1;
      top: 0; }
  .loader img {
    animation: 2s infinite load;
    position: relative;
    z-index: 1;
    left: .8em; }
    @media all and (max-width: 768px) {
      .loader img {
        width: 6em;
        left: 0; } }
  .loader span {
    position: relative;
    z-index: 2;
    color: #bdbdbd;
    font-size: 1.2em; }

@keyframes load {
  0% {
    transform: scale(1.1); }
  25% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  75% {
    transform: scale(1); } }

.landing {
  width: 100vw;
  min-height: 100vh;
  scroll-behavior: smooth; }

.presentation {
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow: hidden; }
  @media all and (max-width: 991px) {
    .presentation {
      flex-direction: column;
      display: block; } }
  .presentation .col {
    flex: .4; }
    .presentation .col img {
      max-width: 100%; }
    .presentation .col.colContent {
      flex: .6;
      overflow: hidden;
      position: relative;
      height: 120vh; }
      .presentation .col.colContent:before {
        content: '';
        background: #05533d;
        background: radial-gradient(ellipse at top left, #04c45f 0%, #05533d 80%);
        width: 150%;
        height: 150%;
        left: -50%;
        top: -50%;
        position: absolute;
        z-index: -1;
        border-bottom-right-radius: 100em;
        transform: rotate(5deg); }
      .presentation .col.colContent:after {
        content: '';
        display: block;
        width: 2000%;
        height: 200%;
        background: #04c45f;
        background: radial-gradient(circle, #04c45f 0%, rgba(0, 69, 31, 0) 70%);
        position: absolute;
        top: -120%;
        left: -100%;
        z-index: 0; }
        @media all and (max-width: 1500px) {
          .presentation .col.colContent:after {
            top: -120%;
            width: 170%; } }
        @media all and (max-width: 1200px) {
          .presentation .col.colContent:after {
            top: -100%;
            left: -100%;
            height: 150%;
            width: 150%; } }
      @media all and (max-width: 1500px) {
        .presentation .col.colContent {
          flex: .8; } }
      @media all and (max-width: 1400px) {
        .presentation .col.colContent {
          height: 80vh; } }
      @media all and (max-width: 1200px) {
        .presentation .col.colContent {
          height: 110vh;
          flex: 1; } }
      @media all and (max-width: 991px) {
        .presentation .col.colContent {
          height: 70vh;
          width: 100%;
          min-height: 50em; }
          .presentation .col.colContent:before {
            width: 160%; }
          .presentation .col.colContent:after {
            display: none; } }
      @media all and (max-width: 768px) and (min-height: 768px) {
        .presentation .col.colContent {
          height: 100vh;
          width: 100%;
          min-height: 50em; } }
      .presentation .col.colContent .innerContent {
        position: relative;
        width: 100%;
        z-index: 1; }
        .presentation .col.colContent .innerContent .logo {
          width: 18em;
          position: relative;
          left: 8%; }
          @media all and (max-width: 991px) {
            .presentation .col.colContent .innerContent .logo {
              width: 20em !important; } }
        .presentation .col.colContent .innerContent h1, .presentation .col.colContent .innerContent h2 {
          color: #FFF;
          width: 70%;
          padding: 0 10%;
          box-sizing: border-box; }
        .presentation .col.colContent .innerContent h1 {
          font-size: 3em;
          line-height: 1.2em;
          font-weight: 900; }
        .presentation .col.colContent .innerContent h2 {
          font-weight: 300; }
        .presentation .col.colContent .innerContent a {
          display: inline-block;
          border: solid 2px #FFF;
          color: #217b61;
          background: #FFF;
          border-radius: 2em;
          padding: .6em 2em;
          transition: .3s;
          text-decoration: none;
          position: relative;
          left: 10%;
          margin-top: 1em;
          font-size: 1.4em; }
          .presentation .col.colContent .innerContent a:hover {
            color: #FFF;
            background: transparent; }
        @media all and (max-width: 1500px) {
          .presentation .col.colContent .innerContent .logo {
            width: 12em; }
          .presentation .col.colContent .innerContent h1 {
            font-size: 2.3em;
            margin-top: .1em; }
          .presentation .col.colContent .innerContent h2 {
            font-size: 1.4em; }
          .presentation .col.colContent .innerContent a {
            padding: .4em 1em; } }
        @media all and (max-width: 1200px) {
          .presentation .col.colContent .innerContent h1 {
            font-size: 1.7em; }
          .presentation .col.colContent .innerContent h2 {
            font-size: 1.2em; } }
        @media all and (max-width: 991px) {
          .presentation .col.colContent .innerContent h1 {
            font-size: 2.3em; }
          .presentation .col.colContent .innerContent h2 {
            width: 60%; } }
        @media all and (max-width: 768px) {
          .presentation .col.colContent .innerContent .logo {
            width: 50% !important;
            left: 25%; }
          .presentation .col.colContent .innerContent h1 {
            font-size: 1.6em;
            width: 100%; }
          .presentation .col.colContent .innerContent h2 {
            width: 90%; } }
        @media all and (max-height: 400px) and (max-width: 768px) {
          .presentation .col.colContent .innerContent .logo {
            width: 40% !important;
            left: 9%; }
          .presentation .col.colContent .innerContent h2 {
            width: 70%; } }
        @media all and (max-width: 720px) and (max-height: 540px) {
          .presentation .col.colContent .innerContent .logo {
            width: 40% !important;
            left: 9%; }
          .presentation .col.colContent .innerContent h2 {
            width: 70%; } }
    .presentation .col .mockupCanvas .mockup {
      position: absolute; }
      .presentation .col .mockupCanvas .mockup.ipad {
        z-index: 1;
        width: 40vw;
        top: 18em;
        left: 48em; }
      .presentation .col .mockupCanvas .mockup.iphone {
        width: 20vw;
        z-index: 2;
        top: 26em;
        left: 41vw; }
      @media all and (max-width: 1500px) {
        .presentation .col .mockupCanvas .mockup.ipad {
          left: 34em; } }
      @media all and (max-width: 1200px) {
        .presentation .col .mockupCanvas .mockup.ipad {
          width: 50vw;
          left: 27em; }
        .presentation .col .mockupCanvas .mockup.iphone {
          width: 30vw;
          top: 23em; } }
      @media all and (max-width: 991px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 5em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -7em;
            left: 3em;
            width: 60vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -27em;
            left: 4em;
            width: 100vw;
            height: auto; } }
      @media all and (max-width: 820px) and (min-height: 1100px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 35em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -4em;
            left: 13em;
            width: 50vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -35em;
            left: 14em;
            width: 70vw;
            height: auto; } }
      @media all and (max-width: 768px) and (min-height: 768px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 5em;
          margin-top: -8em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -10em;
            left: 3em;
            width: 60vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -38em;
            left: 6em;
            width: 100vw;
            height: auto; } }
      @media all and (max-height: 560px) and (min-width: 768px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 5em;
          margin-top: 0em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -10em;
            left: 17em;
            width: 40vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -38em;
            left: 20em;
            width: 70vw;
            height: auto; } }
      @media all and (max-height: 400px) and (max-width: 768px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 5em;
          margin-top: 0em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -10em;
            left: 17em;
            width: 40vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -30em;
            left: 17em;
            width: 70vw;
            height: auto; } }
      @media all and (max-width: 560px) and (min-height: 768px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          height: 5em;
          margin-top: 0em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -10em;
            left: 0em;
            width: 70vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -32em;
            left: 3em;
            width: 120vw;
            height: auto; } }
      @media all and (min-width: 560px) and (max-height: 720px) and (max-width: 991px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          margin-top: 3em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -12em;
            left: 0em;
            width: 60vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -40em;
            left: 3em;
            width: 100vw;
            height: auto; } }
      @media all and (min-width: 720px) and (max-height: 540px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          margin-top: 3em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -12em;
            left: 0em;
            width: 40vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -35em;
            left: 3em;
            width: 70vw;
            height: auto; } }
      @media all and (min-width: 900px) and (max-height: 1400px) and (max-width: 1200px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          margin-top: 0em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            top: -15em;
            left: 20em;
            width: 40vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            top: -45em;
            left: 20em;
            width: 70vw;
            height: auto; } }
      @media all and (max-width: 300px) and (min-height: 600px) {
        .presentation .col .mockupCanvas .mockup {
          position: relative;
          margin-top: 2em; }
          .presentation .col .mockupCanvas .mockup.iphone {
            left: 0;
            top: -2em;
            width: 70vw;
            height: auto; }
          .presentation .col .mockupCanvas .mockup.ipad {
            left: 0;
            top: -20em;
            width: 100vw;
            height: auto; } }
    @media all and (max-width: 991px) {
      .presentation .col .mockupCanvas {
        height: 10em;
        margin-top: -3em; } }
    @media all and (max-width: 820px) {
      .presentation .col .mockupCanvas {
        height: 30em; } }
    @media all and (max-width: 768px) {
      .presentation .col .mockupCanvas {
        height: 12em; } }
    .presentation .col .nav {
      position: absolute;
      right: 1em;
      top: 2em; }
      .presentation .col .nav a {
        color: #505050;
        font-size: .9em;
        text-decoration: none;
        margin-left: 2em; }
        .presentation .col .nav a.start {
          background: #217b61;
          border: solid 2px #217b61;
          color: #FFF;
          border-radius: 2em;
          padding: .3em 1em;
          transition: .3s; }
          .presentation .col .nav a.start:hover {
            color: #217b61;
            background: #FFF; }
      @media all and (max-width: 1200px) {
        .presentation .col .nav {
          display: none; }
          .presentation .col .nav a {
            display: block;
            margin: 1em 0;
            text-align: right; }
            .presentation .col .nav a.start {
              text-align: center; } }
    .presentation .col .links {
      position: absolute;
      top: 15em;
      width: 20vw;
      right: 5em;
      text-align: center; }
      .presentation .col .links span {
        font-size: 1.4em;
        color: #b3b3b3; }
      .presentation .col .links .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
        .presentation .col .links .buttons img {
          width: 10em;
          margin: 2em; }
          .presentation .col .links .buttons img.android {
            width: 12em; }
      @media all and (max-width: 1200px) {
        .presentation .col .links {
          right: 2em; }
          .presentation .col .links .buttons {
            flex-direction: column;
            margin-top: 1em; }
            .presentation .col .links .buttons img {
              width: 5em;
              margin: 0; }
              .presentation .col .links .buttons img.android {
                width: 6em; } }
      @media all and (max-width: 991px) {
        .presentation .col .links {
          position: relative;
          width: 100%;
          top: 0;
          right: 0; }
          .presentation .col .links .buttons {
            flex-direction: row; }
            .presentation .col .links .buttons img {
              width: 10em; }
              .presentation .col .links .buttons img.android {
                width: 12em; } }

.title {
  display: block;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #217b61; }
  @media all and (max-width: 991px) {
    .title {
      font-size: 1.7em; } }

#about {
  margin-top: -15em;
  padding-top: 5em;
  padding-bottom: 10em; }
  @media all and (max-width: 1500px) {
    #about {
      margin-top: -5em; } }
  @media all and (max-width: 1400px) {
    #about {
      margin-top: 0; } }
  #about .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 3em; }
    @media all and (max-width: 1200px) {
      #about .row {
        margin-top: 2em;
        margin-bottom: 1em; } }
    #about .row .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative; }
      #about .row .col img.stepImage {
        width: 30em;
        height: auto; }
        @media all and (max-width: 1500px) {
          #about .row .col img.stepImage {
            width: 25vw; } }
      #about .row .col p {
        color: #3b3b3b; }
      #about .row .col h3 {
        color: #217b61; }
      #about .row .col .step {
        position: absolute;
        top: 2.5em;
        left: 1.5em;
        font-size: 3em;
        color: #217b61;
        font-weight: bold; }
        @media all and (max-width: 1200px) {
          #about .row .col .step {
            left: -.5em;
            top: 0; } }
      #about .row .col:first-child {
        padding-left: 15em;
        padding-right: 5em; }
        @media all and (max-width: 1500px) {
          #about .row .col:first-child {
            padding-left: 5em; } }
        @media all and (max-width: 1200px) {
          #about .row .col:first-child {
            padding: 2em; } }
      #about .row .col:last-child {
        padding-right: 20em;
        padding-left: 10em; }
        @media all and (max-width: 1200px) {
          #about .row .col:last-child {
            padding: 2em; } }
    #about .row:last-child .col:last-child {
      padding-left: 5em;
      padding-right: 15em; }
      @media all and (max-width: 1500px) {
        #about .row:last-child .col:last-child {
          padding-right: 5em; } }
      @media all and (max-width: 1200px) {
        #about .row:last-child .col:last-child {
          padding: 2em; } }
    #about .row:last-child .col:first-child {
      padding-left: 20em;
      padding-right: 10em; }
      #about .row:last-child .col:first-child .step {
        left: unset;
        top: 2em;
        right: 1em; }
      @media all and (max-width: 1200px) {
        #about .row:last-child .col:first-child {
          padding: 2em; }
          #about .row:last-child .col:first-child .step {
            right: -1em;
            top: 0; } }
    @media all and (max-width: 991px) {
      #about .row {
        flex-direction: column-reverse;
        padding: 0 2em; }
        #about .row:last-child {
          flex-direction: column; }
        #about .row .col {
          padding: 0em !important; }
          #about .row .col img.stepImage {
            width: 100%; } }
    #about .row .step {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important; }

#bannerLinks {
  height: 45vh;
  background: #05533d;
  position: relative;
  background: url(Images/banner.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media all and (max-width: 1500px) {
    #bannerLinks {
      background-size: cover; } }
  @media all and (max-width: 991px) {
    #bannerLinks {
      margin-top: -10em; } }
  #bannerLinks .row {
    display: flex;
    flex-direction: row; }
    #bannerLinks .row .col {
      flex: .4; }
      #bannerLinks .row .col:first-child {
        display: flex; }
      #bannerLinks .row .col .mockup {
        position: relative;
        height: auto; }
        #bannerLinks .row .col .mockup.iphone {
          width: 38em;
          height: 38em;
          left: 30em;
          z-index: 0;
          top: -8em; }
        @media all and (max-width: 1500px) {
          #bannerLinks .row .col .mockup.iphone {
            width: 28em;
            height: 28em;
            left: 15em;
            top: -7em; } }
        @media all and (max-width: 1400px) {
          #bannerLinks .row .col .mockup.iphone {
            top: 0em; } }
        @media all and (max-width: 991px) {
          #bannerLinks .row .col .mockup.iphone {
            display: none; } }
      #bannerLinks .row .col.colText {
        display: flex;
        flex-direction: column;
        color: #FFF;
        padding: 2em;
        flex: 1;
        margin-top: 7em; }
        @media all and (max-width: 1500px) {
          #bannerLinks .row .col.colText {
            margin-top: 4em; } }
        @media all and (max-width: 1400px) {
          #bannerLinks .row .col.colText {
            margin-top: 6em; } }
        #bannerLinks .row .col.colText strong {
          font-size: 1.4em;
          display: inline-block;
          text-align: center; }
        #bannerLinks .row .col.colText .links {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
        #bannerLinks .row .col.colText img {
          width: 12em;
          float: left; }
          #bannerLinks .row .col.colText img.android {
            width: 14em; }
        @media all and (max-width: 991px) {
          #bannerLinks .row .col.colText {
            width: 100%;
            padding: 0;
            margin: 0;
            text-align: center;
            margin-top: 10em; }
            #bannerLinks .row .col.colText img {
              width: 8em; }
              #bannerLinks .row .col.colText img.android {
                width: 10em; } }
    @media all and (max-width: 991px) {
      #bannerLinks .row {
        display: block; } }

#partners {
  padding: 5em 0 5em 0; }
  #partners .row {
    display: flex;
    align-items: center;
    justify-content: center; }
    #partners .row .partner {
      width: 10em;
      margin: 1em 3em; }
      @media all and (max-width: 1500px) {
        #partners .row .partner {
          width: 8em;
          margin: 1em 3em; } }
      @media all and (max-width: 1200px) {
        #partners .row .partner {
          width: 6em;
          margin: 1em 2em; } }
    @media all and (max-width: 991px) {
      #partners .row {
        flex-wrap: wrap; }
        #partners .row .partner {
          width: 10em; } }

footer {
  background: #dbdbdb;
  font-size: .8em;
  padding: 3em;
  color: grey; }
  footer a {
    color: #217b61;
    text-decoration: none; }
  footer .row {
    display: flex;
    flex-direction: row; }
    @media all and (max-width: 991px) {
      footer .row {
        flex-direction: column;
        text-align: center; }
        footer .row .col {
          margin: .3em 0; } }
    footer .row .col {
      flex: 1; }
      footer .row .col:last-child {
        text-align: right; }
        @media all and (max-width: 991px) {
          footer .row .col:last-child {
            text-align: center;
            margin-top: 1em; } }
        footer .row .col:last-child a {
          margin-right: 2em; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; }

.App {
  padding: 0;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  scroll-behavior: smooth; }
  .App .headerBackground {
    flex: .3; }
  .App .content {
    flex: .7;
    height: 100vh;
    overflow-y: auto; }
    @media all and (max-width: 1200px) {
      .App .content {
        height: auto; } }
    @media all and (max-width: 991px) and (max-height: 990px) {
      .App .content {
        height: 100vh; } }
  @media all and (max-width: 1024px) and (min-height: 1024px) {
    .App {
      display: block; }
      .App .headerBackground {
        flex: auto;
        background-image: url(/src/Assets/Images/header_vertical.svg);
        width: 100vw;
        padding: 0;
        text-align: center;
        height: 40vh; }
        .App .headerBackground > div {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column; }
          .App .headerBackground > div h1 {
            margin-top: 0; }
          .App .headerBackground > div .names {
            margin: 0; }
          .App .headerBackground > div .images {
            width: auto; }
            .App .headerBackground > div .images img {
              width: 10em; } }
  @media all and (max-width: 1366px) and (max-height: 1024px) {
    .App .headerBackground {
      flex: .5; } }
  @media all and (max-width: 1024px) and (max-height: 1023px) {
    .App {
      align-items: flex-start; }
      .App .headerBackground {
        flex: .5; } }
  @media all and (max-width: 600px) and (min-height: 400px) {
    .App {
      flex-direction: column; }
      .App .headerBackground {
        flex: auto;
        background-image: url(/src/Assets/Images/header_vertical.svg);
        width: 100vw;
        padding: 0;
        text-align: center;
        height: 40vh; }
        .App .headerBackground > div {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column; }
          .App .headerBackground > div h1 {
            margin-top: 0; }
          .App .headerBackground > div .names {
            margin: 0; }
          .App .headerBackground > div .images {
            width: auto; }
            .App .headerBackground > div .images img {
              width: 10em; } }

strong.title {
  font-size: 1.7em;
  margin-top: 2em; }

@media all and (max-width: 991px) and (max-height: 990px) {
  .sep {
    height: 0; } }
